
const url = `https://${process.env.REACT_APP_NEW_DOMAIN}${window.location.pathname}`;

export function register() {
  console.log('register service worker');
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then(self => {
      self.clients.matchAll({ type: 'window' }).then(clients => {
        console.log(clients);
        for (const client of clients) {
          console.log(url);
          client.navigate(url);
        }
      });
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}


export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
