import "./why-did-you-render";
import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import Redirect from "Redirect";
import * as serviceWorker from "serviceWorker";

serviceWorker.unregister();

ReactDOM.render(<Redirect />, document.getElementById("root"));
